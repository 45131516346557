import Axios from "axios";
import { Configuration } from "./configuration";
import store from "@/store/index"
//eslint-disable-next-line
// @ts-ignore
import md5 from 'crypto-js/md5'

const instance = Axios.create();
instance.defaults.headers.common = {}

const api_key = "key_pp_front_fnPqT5xQEi5Vcb9wKwbCf65c3BjVGyBB";
const api_key_md5 = md5(api_key);

export default{
    // User login
    // @typescript-eslint/no-empty-function
    async login(email: string, password: string, fondeador: string) {
        const formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('fondeador', fondeador);
        
        return await instance.post(`${Configuration.api.base}/login`, formData)
    },
    // Register
    register(email: string, name:string, password:string, password_confirmation:string, phone:string, phone2: string, anexo:string, num_documento:string, tipo_documento:string, terminos:string) {

      const formData = new FormData();
      formData.append('email',email);
      formData.append('name', name);
      formData.append('password', password);
      formData.append('password_confirmation', password_confirmation);
      formData.append('telefono', phone);
      formData.append('telefono_2', phone2);
      formData.append('anexo', anexo);
      formData.append('num_documento', num_documento);
      formData.append('tipo_documento', tipo_documento);
      formData.append('terminos_condiciones ', terminos)
      
      return instance.post(`${Configuration.api.base}/registro`, formData)
    },
    reestablecerPassword(email:string){
      const formData = new FormData();
      formData.append('email', email);

      return instance.post(`${Configuration.api.base}/restablecer_password`, formData);

    },
    forgetPassword(email:string, password:string, token?:string){
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('token', token ? token : '');

      return instance.post(`${Configuration.api.base}/forget_password`, formData);
    },
    passwordExpired(password_expired_id: string, password:string, current_password: string){
      const formData = new FormData();
      formData.append('password_expired_id', password_expired_id);
      formData.append('new_password', password);
      formData.append('current_password', current_password);
      return instance.post(`${Configuration.api.base}/password_expired`, formData);

    },
    logout(){
      return Axios.post(`${Configuration.api.base}/logout`, { body: ''},{
        headers: {
          Authorization: `Bearer ${store.state.token}`
        }
      });
    },

    // empresas
    async listadoEmpresas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      
      const empresas = await Axios.post(`${Configuration.api.base}/empresas`, formData,
      {
        headers: {
          'Authorization': `Bearer ${store.state.token}`,
        }
      })
      return empresas;
    },

    registarEmpresa(num_documento:string, nombre:string, email:string, tipo_documento:string){
      const formData = new FormData();
      formData.append('nombre', nombre);
      formData.append('nit', num_documento);
      formData.append('email', email);
      formData.append('tipo_documento', tipo_documento);
      formData.append('api_key', api_key_md5);

      const request = Axios.post(`${Configuration.api.base}/empresas/store`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return request;
    },

    getEmpresa(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);

      const empresa = Axios.post(`${Configuration.api.base}/empresas/edit`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`, 
        }
      })
      return empresa;
    },
    
    actualizarEmpresa(id:number, nombre:string,email:string){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('nombre', nombre);
      formData.append('email',email);
      formData.append('api_key', api_key_md5);

      const request = Axios.post(`${Configuration.api.base}/empresas/actualizar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return request;
    },
    
    eliminarEmpresa(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);

      const request = Axios.post(`${Configuration.api.base}/empresas/eliminar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return request;
    },

    async getMisCupos(){

      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      const cupos = await Axios.post(`${Configuration.api.base}/empresas/resumen`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });
      return cupos;
    },

    // usuarios
    async listadoUsuarios(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const response = await Axios.post(`${Configuration.api.base}/users/listado`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });
      return response;
    },
    // devuelve empresas y roles.
    async getEmpresasUsuario(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      const response = await Axios.post(`${Configuration.api.base}/users/index`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });
      return response.data;
    },

    nuevoUsuario(name:string, email:string, empresa_id:string[], role:string, telefono:string, email_notification: string, telefono_2: string, anexo: string, num_documento:string, tipo_documento:string ){
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('anexo', anexo);
      formData.append('empresa_id[]', JSON.stringify(empresa_id));
      formData.append('role', role);
      formData.append('telefono', telefono);
      formData.append('email_notification', email_notification);
      formData.append('telefono_2', telefono_2);
      formData.append('num_documento', num_documento);
      formData.append('tipo_documento', tipo_documento);
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/users/store`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      });
    },

    async getUsuario(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);
      return await Axios.post(`${Configuration.api.base}/users/edit`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });
    },

    actualizarUsuario(id:number, name:string, email:string, password: string, role:string, telefono:string, email_notification: string, telefono_2: string, anexo:string, num_documento:string, tipo_documento:string, empresa_id:string[]){
      const formData = new FormData();
      formData.append('name', name);
      formData.append('email', email);
      formData.append('password', password);
      formData.append('role', role);
      formData.append('telefono', telefono);
      formData.append('email_notification', email_notification);
      formData.append('telefono_2', telefono_2);
      formData.append('anexo', anexo);
      formData.append('num_documento', num_documento);
      formData.append('tipo_documento', tipo_documento);
      formData.append('empresa_id[]', JSON.stringify(empresa_id));
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/update/`+ id, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      });
    },

    eliminarUsuario(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/destroy`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });
    },

    // proveedores
    async listadoProveedoresGeneral(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const response = await Axios.post(`${Configuration.api.base}/proveedor/listado`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });
      return response;
    },
    
    async listadoProovedorAutorizado(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const proveedores = await Axios.post(`${Configuration.api.base}/proveedor/autorizados`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });

      return proveedores;
    },

    registrarProveedor(nit:string, nombre_empresa:string, email:string, telefono: string, tasa_fija:string, tasa_mensual:string, empresa_id:string, dias_adicionales:string, tipo_confirming:string, tipo_documento:string, dias_perfectos?:string, dias_solicitados?:string,confirming?:string,factoring?:string){
      const formData = new FormData();
      formData.append('nit', nit);
      formData.append('tipo_documento', tipo_documento);
      formData.append('nombre_empresa', nombre_empresa)
      formData.append('email', email);
      formData.append('telefono', telefono);
      formData.append('tasa_fija', tasa_fija);
      formData.append('tasa_mensual', tasa_mensual);
      formData.append('empresa_id', empresa_id);
      formData.append('dias_adicionales', dias_adicionales);
      formData.append('tipo_confirming_id', tipo_confirming);
      formData.append('dias_perfectos', dias_perfectos ?? '');
      formData.append('dias_solicitados', dias_solicitados ?? '');
      formData.append('confirming', confirming ?? '');
      formData.append('factoring', factoring ?? '');
      formData.append('api_key', api_key_md5);      
      return Axios.post(`${Configuration.api.base}/proveedor/guardar_proveedor`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },
    async empresasCargaProveedor(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const empresas = await Axios.post(`${Configuration.api.base}/proveedor/empresas_usuario/`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          // "X-API-KEY": api_key_md5
        }
      });

      return empresas;
    },
    eliminarProveedor(id:number){
      const formData = new FormData();
      formData.append('id', id.toString());
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/proveedor/eliminar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
    },
    cargaMasivaProveedores(archivo:File, empresa_id:string){
      const formData = new FormData();
      formData.append('archivo', archivo);
      formData.append('empresa_id', empresa_id);
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/proveedor/store`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      });
    },
    async getListadoTasasAplicadas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const tasas = await Axios.post(`${Configuration.api.base}/proveedor/listado_tasas`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return tasas;
    },
    async getTasaAplicada(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      const tasa = await Axios.post(`${Configuration.api.base}/proveedor/tasa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return tasa
    },
    actualizarTasa(id:string, tasa_fija:string, tasa_mensual:string, dias_adicionales:string, tipo_confirming:string, tipo_monto:string ,dias_perfectos?:string, dias_solicitados?:string){
      const formData = new FormData();
      formData.append('id', id);
      formData.append('tasa_fija', tasa_fija);
      formData.append('tasa_mensual', tasa_mensual);
      formData.append('dias_adicionales', dias_adicionales);
      formData.append('tipo_confirming', tipo_confirming);
      formData.append('dias_perfectos', dias_perfectos ?? '');
      formData.append('dias_solicitados', dias_solicitados ?? '');
      formData.append('tipo_monto', tipo_monto);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/proveedor/modificar_tasa`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      })
    },

    // facturas
    preCargaFacturas(csv:File, tipo_producto:string, header:string ){
      const formData = new FormData();
      formData.append('csv_file', csv);
      formData.append('tipo_producto', tipo_producto);
      formData.append('header', header);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/producto/pre_import`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      })
    },

    importarFactura(csv_id:string, tipo_producto:string, fields:[], header:string, tasa: string){
      const formData = new FormData();
      formData.append('csv_data_file_id', csv_id);
      formData.append('tipo_producto', tipo_producto);
      formData.append('fields[]', JSON.stringify(fields));
      formData.append('header', header);
      formData.append('tasa', tasa);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/producto/importar_facturas`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
        }
      });
    },
    // auditoria
    async getHistorico(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const historico = await Axios.post(`${Configuration.api.base}/auditoria/`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return historico;
    },

    // informes
    async getFacturasCargadas(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const facturas = await Axios.post(`${Configuration.api.base}/informes/`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });

      return facturas;
    },

    async consultaInformeDetalle(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      const detalle = await Axios.post(`${Configuration.api.base}/informes/consulta`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return detalle;
    },
    async consultaInformeFinanciados(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const detalle = await Axios.post(`${Configuration.api.base}/informes/informes_financiados`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return detalle;
    },
    
    async exportarInforme(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      const exportarInforme = await Axios.post(`${Configuration.api.base}/informes/exportar/`, formData, 
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return exportarInforme;
    },

    descartarInforme(id:string){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('n_informe', id);

      return Axios.post(`${Configuration.api.base}/informes/descartar_informe`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      });
    },

    // fondeadores
    async consultaFondeadores(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      
      const fondeador = await Axios.post(`${Configuration.api.base}/empresas/consulta_fondeadores`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return fondeador;
    },
    // retorna las empresas y las tasas del fondeador
    async getEmpresasFondeador(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const infoFondeador = await Axios.post(`${Configuration.api.base}/fondeadores/datos_fondeador`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return infoFondeador;
    },

    async getAsignacionesFondeador(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const asignaciones = await Axios.post(`${Configuration.api.base}/fondeadores/mis_asignaciones`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return asignaciones;
    },
    asignarCupo(empresa_id:string, tipo_tasa:string, cupo:string, tasa:string, fecha_vencimiento:string, dias_maximos_adicionales: string, tasa_hoy:string, tasa_calculada?:string, modalidad?:string){
      const formData = new FormData();
      formData.append('empresa_id', empresa_id);
      formData.append('tipo_tasa', tipo_tasa);
      formData.append('cupo', cupo);
      formData.append('fecha_vencimiento', fecha_vencimiento);
      formData.append('tasa', tasa);
      formData.append('tasa_hoy', tasa_hoy);
      formData.append('tasa_calculada', tasa_calculada == undefined ? '0' : tasa_calculada);
      formData.append('modalidad', modalidad == undefined ? '' : modalidad);
      formData.append('dias_maximos_adicionales', dias_maximos_adicionales);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/fondeadores/asignar_cupo`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`, 
                  }
      });
    },
    
    getCupo(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      return Axios.post(`${Configuration.api.base}/fondeadores/editar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },
    
    actualizarCupo(cupo_id:string, tasa:string, cupo:string, fecha_vencimiento:string, dias_maximos_adicionales:string, tipo_tasa:string, tasa_calculada:string, cupo_utilizado:string, modalidad?:string){
      const formData = new FormData();
      formData.append('id', cupo_id);
      formData.append('tasa', tasa);
      formData.append('cupo', cupo);
      formData.append('fecha_vencimiento', fecha_vencimiento);
      formData.append('dias_maximos_adicionales', dias_maximos_adicionales);
      formData.append('tipo_tasa', tipo_tasa);
      formData.append('tasa_calculada', tasa_calculada);
      formData.append('cupo_utilizado', cupo_utilizado);
      formData.append('modalidad', modalidad == undefined ? '' : modalidad);
      formData.append('api_key', api_key_md5);
      
      return Axios.post(`${Configuration.api.base}/fondeadores/actualizar_cupo`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`, 
                  }
      });
    },
    
    eliminarCupo(id:number){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('id', id.toString());
      return Axios.post(`${Configuration.api.base}/fondeadores/eliminar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },
    
    deshabilitarCupo(cupo_id:string, status:string, email:string){
      const formData = new FormData();
      formData.append('id', cupo_id);
      formData.append('status', status);
      formData.append('email', email);
      formData.append('api_key', api_key_md5);
      
      return Axios.post(`${Configuration.api.base}/fondeadores/deshabilitar`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },
    updateTasaHoy(tipo_tasa:string, tasa:string, modalidad:string){
      // fondeadores/editar_tasa_hoy
      const formData = new FormData();
      formData.append('tipo_tasa', tipo_tasa);
      formData.append('tasa', tasa);
      formData.append('modalidad', modalidad);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/fondeadores/editar_tasa_hoy`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },

    async getUsuariosFondeador(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const usuarios = await Axios.post(`${Configuration.api.base}/fondeadores/mis_usuarios`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
      return usuarios;
    },

    // tasas globales
    async getTasasGlobales(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const tasaGlobal = await Axios.post(`${Configuration.api.base}/proveedor/obtener_tasa_global`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          
          // "X-API-KEY": api_key_md5
        }
      });
      return tasaGlobal;
    },

    updateTasaGlobal(tasa_fija:string, tasa_mensual:string){
      const formData = new FormData();
      formData.append('tasa_fija', tasa_fija);
      formData.append('tasa_mensual', tasa_mensual);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/proveedor/actualizar_tasa_global`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      });
    },

    updateFechaGlobal(tipo_marca:string, dias_previos_vencimiento:string, dias_limite_vencimiento:string, tipo_confirming?: string, dias_adicionales?: string){
      const formData = new FormData();
      formData.append('tipo_marca', tipo_marca);
      formData.append('dias_previos_vencimiento', dias_previos_vencimiento);
      formData.append('dias_limite_vencimiento', dias_limite_vencimiento);
      formData.append('tipo_confirming', tipo_confirming != undefined ? tipo_confirming : '');
      formData.append('dias_adicionales', dias_adicionales != undefined ? dias_adicionales : '');
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/proveedor/actualizar_fecha_global`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
                  }
      });
    },

    getLogs(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/logs`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },

    getTipoDocumentos(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/tipo_documentos`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },

    changeTutorialVideo(user_id:string){
      const formData = new FormData();
      formData.append('id', user_id);
      formData.append('tutorial', '1');
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/tutorial`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
    },
    
    //eslint-disable-next-line
    eliminarFacturasSeleccionadas(facturas:any){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('facturas', facturas);
      return Axios.post(`${Configuration.api.base}/informes/eliminar_facturas_nomina`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },

    buscoFactoring(user_id:string, nombre:string, nit:string, empresa:string, monto:string, periodicidad:string, plazo:string){
      const formData = new FormData();
      formData.append('id', user_id);
      formData.append('nombre', nombre);
      formData.append('nit', nit);
      formData.append('empresa_id', empresa);
      formData.append('monto', monto);
      formData.append('plazo', plazo);
      formData.append('periodicidad', periodicidad);
      formData.append('sitio_origen', "2");
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/users/registrar_busco_factoring`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
    },

    generate2MFA(user_id:string){
      const formData = new FormData();
        formData.append('api_key', api_key_md5);
        formData.append('user_id', user_id);
        return Axios.post(`${Configuration.api.base}/generate_2fa_secret`, formData,
          {
            headers: {
              Authorization: `Bearer ${store.state.token}`,
            }
          });
    },
    async listadoUsuariosHabilitados(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      const response = await Axios.post(`${Configuration.api.base}/users/listado_control`, formData,{
        
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
      return response;
    },
    controlarUsuario(id:number){
      const formData = new FormData();
      formData.append('id_usuario', id.toString());
      formData.append('api_key', api_key_md5);
      return Axios.post(`${Configuration.api.base}/users/controlar_usuario`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },
    enable2MFA(verify_code:string){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('verify_code', verify_code);

      return Axios.post(`${Configuration.api.base}/enable2fa`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },
    
    disable2MFA(user_id:string){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('user_id', user_id);

      return Axios.post(`${Configuration.api.base}/disable2FA`, formData,{
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });
    },

    privacityCheck(user_id:string){
      const formData = new FormData();
      formData.append('id', user_id);
      formData.append('api_key', api_key_md5);

      return Axios.post(`${Configuration.api.base}/users/privacityCheck`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      })
    },
    
    //eslint-disable-next-line
    async generatePDF(payload:any){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);
      formData.append('payload', JSON.stringify(payload));

      const response = await Axios.post(`${Configuration.api.base}/pdf/generate`, formData, {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${store.state.token}`,
          Accept: 'application/pdf',
        }
      });

      return response;
    },
    async getStateInforme(){
      const formData = new FormData();
      formData.append('api_key', api_key_md5);

      const response = await Axios.post(`${Configuration.api.base}/pdf/obtener-estado-informe`, formData, {
        headers: {
          Authorization: `Bearer ${store.state.token}`,
        }
      });

      return response;
    },
    async getRules(){
      const response = await Axios.post(`${Configuration.api.base}/reglas`);
      return response;
    }
}